import { Location } from '@angular/common';
import { ItemService } from '../../../../../core/item.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-technical-data',
  templateUrl: './technical-data.component.html',
  styleUrls: ['./technical-data.component.scss']
})
export class TechnicalDataComponent implements OnInit {

  pdf: boolean = false;
  video: boolean = false;
  productId;
  obj :boolean = false;
  obj2 = false;
  items;

  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private _location: Location,
    private service: ItemService
  )
  {
    this.loadData();
  }

  loadData() {
    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getproductitems(this.productId).subscribe(data => {
      let source: any = data;
      console.log(source.data)
      if (!source.data.data)  this.obj2 = true;    
      let a = source.data.filter(res=> res.type == "ftPdf" ||  res.type == "ftVideo");
         a.forEach(data => {

          if(data.type !== "ftPdf"){
            this.obj2 = false;
        }else if(data.type !== "ftVideo"){
          this.obj2 = false;
        }
        if (data.type === "ftPdf") {
          this.pdf = true;
          this.obj = true;
        }
        else if (data.type === "ftVideo")
        {
          this.video = true;
          this.obj = true;
        }
        else
        {
          this.obj2 = false;
          console.log(this.obj)
        }
      });
    });
  }

  ngOnInit() {
  }

}
