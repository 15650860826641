import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common'
import { Router } from '@angular/router';
import { Ruta } from '../../config/routes.model'


@Component({
  selector: 'app-sidbar-prod',
  templateUrl: './sidebar-product.component.html',
  styleUrls: ['./sidebar-product.component.scss']
})
export class SidebarProductComponent implements OnInit {
  id
  route: string;
  home;
  Url;
  class: string;
  @Input() view: any;
  @Input() product: any;

  @HostListener('click')
  click() {
    this.product;
    this.view;
  }

  constructor(private _location: Location, private router: Router, private cdRef: ChangeDetectorRef,private _route: Ruta) {

  }

  test() {

  }
  loadfavs() {
    let favs = [];
    favs = JSON.parse(localStorage.getItem('favs'));
    let lengh = [];


    console.log(lengh, this.product.id)
    if (favs.find(res => res.id == this.product.id)) {
      this.class = "star";
    } else {
      this.class = "star_border";
    }
  }
  favs() {
    let favs = [];
    favs = JSON.parse(localStorage.getItem('favs'));
    let lengh = [];

    if (favs.find(res => res.id == this.product.id)) {
      lengh = favs.filter(res => res.id != this.product.id);
      let a = JSON.stringify(lengh);
      localStorage.setItem('favs', a);
      this.class = "star_border";
      console.log("quito")
    } else {
      let c = {}
      c = {
        description: this.product.description,
        id: this.product.id,
        id_media: this.product.id_media,
        path_data: this.product.path_data,
        product: this.product.product,
        status: this.product.status,
        sub_category_id: this.product.sub_category_id,
        url: this.router.url
      }
      console.log(c)
      favs.push(c)
      let a = JSON.stringify(favs);
      localStorage.setItem('favs', a);
      this.class = "star";
      console.log("agrego")
    }


  }
  ngOnInit() {
    this.route = this.router.url;
    console.log(this.route)
  }

  ngAfterViewInit() {
    if (this.view) {

      this.loadfavs();
    }
    this.cdRef.detectChanges();
  }
  

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("nav").style.display = "none";

  }
  setvalueshome() {
    document.getElementById("imgc1").style.height = screen.height * 0.45 + "px";
    document.getElementById("imgc2").style.height = screen.height * 0.45 + "px";
    document.getElementById("imgc3").style.height = screen.height * 0.45 + "px";
    document.getElementById("logos").style.height = screen.height * 0.28 + "px";

  }
  setvalues() {
    document.getElementById("html").style.maxHeight = screen.height + "px";
    document.getElementById("html").style.height = screen.height + "px";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // document.body.style.backgroundColor = "white";
    document.getElementById("nav").style.display = "";
  }
  items(info) {
    document.getElementById("html").style.maxHeight = screen.height + "px";
    document.getElementById("html").style.height = screen.height + "px";
    document.getElementById("html").style.backgroundColor = "#EDEDED";

  }
  
  atras(){
    /* Validacion Error Back */
     if(this._route.id = 1){
         console.log(this._route.id)
         this._location.back();
      
    }else{
      this._location.back();
    }  
  }
   
}
