import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';




@Component({
  selector: 'app-pdf-static-view',
  templateUrl: './pdf-cert-static-view.component.html',
  styleUrls: ['./pdf-cert-static-view.component.scss']
})
export class PdfCertStaticViewComponent implements OnInit {

 
  namePdf;
  
  constructor(
    private router: ActivatedRoute,
    private navigate: Router)
  {
    this.getRuta();
  }
  ngOnInit(){
  }

 getRuta(){
  this.namePdf = this.router.snapshot.paramMap.get('path');
  console.log(this.namePdf)

} 



}
