import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ItemService } from '../core/item.service';



@Component({
  selector: 'app-pdf-static-view',
  templateUrl: './pdf-static-view.component.html',
  styleUrls: ['./pdf-static-view.component.scss']
})
export class PdfStaticViewComponent implements OnInit {

 
  namePdf;
  
  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private service: ItemService)
  {
    this.getRuta();
  }
  ngOnInit(){
  }

 getRuta(){
  this.namePdf = this.router.snapshot.paramMap.get('path');
  console.log(this.namePdf)

} 


}
