import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from 'src/app/core/item.service';
import * as v from '../../../../../../../assets/minejs'
@Component({
  selector: 'app-ar',
  templateUrl: './ar.component.html',
  styleUrls: ['./ar.component.scss']
})
export class ArComponent implements OnInit {

  items = [];
  productId;

  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private service: ItemService)
  {
    this.loadData();
  }
  ngOnInit() {
  }

    openLevel(a){
      let open = v;
      let name:string;
      name= a.name;
      let b = name.split('.');
      open.open(b[0])
      document.title = `Lv_${name}`;
    }

    
  
  loadData() {
    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getproductitems(this.productId).subscribe(data => {
      let source: any = data;
      console.log(source.data)
      this.items = source.data;
      this.items.forEach(res=> {
        let name:string;
        name= res.name;
        let b = name.split('.');
        res.name = b[0]
      })
      let a = this.items.filter(res => res.type === "modl3DAr")
      this.items = a
    });
  }

}
