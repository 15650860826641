import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(public http: HttpClient) { }

  server = "https://frontapp.m3d-ar.com"

  getCategory() {
    let json = {
      id: '3'
    }
    let params = json;
    return this.http.get(`${this.server}/backfive/category`, { params })
  }
}
