import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { Location } from '@angular/common';
import { CategoryService } from '../core/category.service';
import { timeout } from 'rxjs/operators';
import { interval } from 'rxjs';
import {Router} from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component'
import { Ruta } from '../config/routes.model'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})


export class ProductComponent implements OnInit {
  @ViewChild(SidebarComponent, {static: false}) navimg: SidebarComponent;
  @ViewChild('back', {static: false}) back : ElementRef;
   _back;
  id;
  categories: any[];
  public loading: boolean;
  constructor(private service: CategoryService, private _location: Location, private _router : Router, private route: Ruta )
  {
    this.id = new Ruta()
      this.id={
        id: 1
    }
    this.loadData();
    this.loading = true;


  }

  ngOnInit() {
  }


  loadData()
  {
    const seconds = interval(2000);
    this.service.getCategory().subscribe( data => {
      let source: any = data;
      this.categories = source.data;
      this.loading = false;
    });
  }

 
}
