import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ItemService } from '../../../../core/item.service';
import { Router, ActivatedRoute } from '@angular/router';
// import * as v from '../../../../../assets/minejs.js';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {

  item: any;
  productId;
  items=[];
  qx:boolean;
  modl3D: boolean;
  ftPdf: boolean;
  animation: boolean;
  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private service: ItemService)
  {
    this.qx = true;
    this.modl3D = true;
    this.ftPdf = true;
    this.animation = true;
    this.loadData();
    this.loadItems();
  }

  ngOnInit() {
    this.loadItems();
  }

    // test(te){
    //   let a = v;
    //   console.log(a);
    //   a.test(te);
    // }

  loadData() {
    this.productId = this.router.snapshot.paramMap.get('id');
    console.log(this.productId)
    this.service.getItemById(this.productId).subscribe(data => {
      let source: any = data;
      console.log(source.data)
      this.item = source.data;
    });
  }

  loadItems() {
    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getproductitems(this.productId).subscribe(data => {
      let source: any = data;
    /*   if(!source.data.data) this.obj2 = true; */
      console.log(source.data, "← Datos perros")
      this.items = source.data;
      
      this.items.forEach(data=>{
        console.log(data.type,  "←datatype")
      if(data.type == "insQx" ){  
        document.getElementById("imgQx").style.filter = 'grayscale(0)'
        this.qx= false;      
      }
      
      if(data.type == "modl3DAr" || data.type == "modl3DVr"){
        document.getElementById("modl3D").style.filter = 'grayscale(0)'
        this.modl3D = false;
      }
      if(data.type == "animation"){
        document.getElementById("animation").style.filter = 'grayscale(0)'
        this.animation = false;
      }
      if(data.type == "ftPdf" || data.type == "ftVideo" ){
        document.getElementById("ftPdf").style.filter = 'grayscale(0)'
        this.ftPdf = false;
      }

     })
      
    });
  }
  
}
