import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ItemService } from '../core/item.service';



@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit {

 
  productId;
  path;

  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private service: ItemService)
  {
    this.Aver();
  }
  ngOnInit(){
  }

 Aver(){

    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getPDF(this.productId).subscribe(data =>{
        this.path = data.path_data
    }) 
  } 
}
