import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {

  items;
  obj = {};
  constructor() {
    this.items = JSON.parse(localStorage.getItem('favs'));
  }

  ngOnInit() {
  }

}
