import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemService } from 'src/app/core/item.service';
import * as v from '../../../../../../../assets/minejs'

@Component({
  selector: 'app-vr',
  templateUrl: './vr.component.html',
  styleUrls: ['./vr.component.scss']
})
export class VrComponent implements OnInit {

  items = [];
  productId;

  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private service: ItemService)
  {
    this.loadData();
  }
  ngOnInit() {
    
  }

  
  openLevel(a){
    let open = v;
    let name:string;
    name= a.name;
    let b = name.split('.');
    open.open(b[0])
    document.title = `Lv_${name}`;
  }
  loadData() {
    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getproductitems(this.productId).subscribe(data => {
      let source: any = data;
      console.log(source.data)
      this.items = source.data;
      this.items.forEach(res=> {
        let name:string;
        name= res.name;
        let b = name.split('.');
        res.name = b[0]
      })
      let a = this.items.filter(res => res.type === "modl3DVr")
      this.items = a
    });
  }

}
