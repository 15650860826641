import { Component, OnInit } from '@angular/core';
import { ItemService } from '../core/item.service';

@Component({
  selector: 'app-ar',
  templateUrl: './ar.component.html',
  styleUrls: ['./ar.component.scss']
})
export class ARComponent implements OnInit {

  products;
  obj2 = false;
  obj: {};
  ruta: string;

  constructor(private service: ItemService)
  {
    this.loadData();
  }

  ngOnInit() {
  }

  loadData() {
    this.service.getItemAr().subscribe(data => {
      let source: any = data;
      this.obj = Object.keys(source.data).length === 0;
      console.log(source.data,"Alv wee");
      if (this.obj !== 'undefined' && source.data.length > 0) {
        for (let ani of source.data) {
          if (ani.status === 1) {
            this.products = source.data;
            this.obj= true;
            this.ruta = '/product-list/details/'+ ani.category_id +'/item/'+ ani.sub_category_id +'/item-details/' + ani.id;
            console.log(this.ruta)
          } else {
            this.obj2 = true;
          }
        }
        console.log(this.products)
      } else {
        console.log(this.obj)
      }
    });
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("nav").style.display = "none";

  }
  setvalueshome() {
    document.getElementById("imgc1").style.height = screen.height * 0.45 + "px";
    document.getElementById("imgc2").style.height = screen.height * 0.45 + "px";
    document.getElementById("imgc3").style.height = screen.height * 0.45 + "px";
    document.getElementById("logos").style.height = screen.height * 0.28 + "px";

  }
  setvalues() {
    document.getElementById("html").style.maxHeight = screen.height + "px";
    document.getElementById("html").style.height = screen.height + "px";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // document.body.style.backgroundColor = "white";
    document.getElementById("nav").style.display = "";
  }
  
  items(info) {
    document.getElementById("html").style.maxHeight = screen.height + "px";
    document.getElementById("html").style.height = screen.height + "px";
    document.getElementById("html").style.backgroundColor = "#EDEDED";

  }
}
