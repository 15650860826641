import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ARComponent } from './ar/ar.component';
import { ProductComponent } from './product/product.component';
import { ClavosComponent } from './product/clavos/clavos.component';
import { CertificadosComponent } from './certificados/certificados.component';
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DetailsComponent } from './product/details/details.component';
import { ItemDetailsComponent } from './product/details/item/item-details/item-details.component';
import { ItemComponent } from './product/details/item/item.component';
import { TechnicalDataComponent } from './product/details/item/item-details/technical-data/technical-data.component';
import { InsQxComponent } from './product/details/item/item-details/ins-qx/ins-qx.component';
import { PdfComponent } from './product/details/item/item-details/technical-data/pdf/pdf.component';
import { VideoComponent } from './product/details/item/item-details/technical-data/video/video.component';
import { AnimationComponent } from './product/details/item/item-details/animation/animation.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { Model3dComponent } from './product/details/item/item-details/model3d/model3d.component';
import { VrComponent } from './product/details/item/item-details/model3d/vr/vr.component';
import { ArComponent } from './product/details/item/item-details/model3d/ar/ar.component';
import { SidebarFavComponent } from './sidebarFavoritos/sidebarFav.component';
import { Ruta } from './config/routes.model';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatVideoModule } from 'mat-video';
import { SidebarProductComponent } from './sidebar/sidebar-products/sidebar-product.component';
import { SidebarProductosComponent } from './sidebar/sidebar-productos/sidebar-productos.component';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule
import { PdfViewComponent } from './pdf-view/pdf-view.component';
import { PdfStaticViewComponent } from './pdf-static-view/pdf-static-view.component';
import { PdfCertStaticViewComponent } from './pdf-static-view/pdf-cert-static-view/pdf-cert-static-view.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ARComponent,
    ProductComponent,
    DetailsComponent,
    CertificadosComponent,
    HomeComponent,
    SidebarComponent,
    InsQxComponent,
    ItemDetailsComponent,
    ItemComponent,
    TechnicalDataComponent,
    PdfComponent,
    VideoComponent,
    Model3dComponent,
    AnimationComponent,
    FavoriteComponent,
    VrComponent,
    ArComponent,
    SidebarFavComponent,
    SidebarProductComponent,
    SidebarProductosComponent,
    ClavosComponent,
    PdfViewComponent,
    PdfStaticViewComponent,
    PdfCertStaticViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatVideoModule,
    ImgFallbackModule,
    PdfViewerModule, // <- Add PdfViewerModule to imports
  ],
  providers: [
    Ruta
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
