import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(public http: HttpClient) { }

  server = "https://frontapp.m3d-ar.com"

  getItem(catId, subCatId)
  {
    let json = {
      catId: catId,
      subCatId: subCatId,
      client_id: '3'
    }
    let params = json;
    return this.http.get(`${this.server}/backfive/products`, { params })
  }

  getItemById(Id)
  {
    let json = {
      id: Id,
      client_id: '3'
    }
    let params = json;
    return this.http.get(`${this.server}/backfive/productbyid`, {params})
  }

  getproductitems(Id)
  {
    let json = {
      id: Id,
      client_id: '3'
    }
    let params = json;
    return this.http.get(`${this.server}/backfive/productitems`, {params})
  }

  getItemAr()
  {
    let json = {
      client_id: '3'
    }
    let params = json;
    return this.http.get(`${this.server}/backfive/productar`, {params})
  }
  getPDF(id)
  {
    return this.http.get<any>(`${this.server}/backfive/pdf/${id}`)
  }
}
