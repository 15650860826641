import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(){
  /*   if('caches' in window) {
      console.log('hay cache')
      caches.open('test-cache').then(function(cache) {
          cache.addAll([
            '~/app/app/',
            '~/app/app/index.html',
            '~/app/app.component.html/',
          ])
      });
    } */
  /* if (localStorage.getItem('favs')===null ) {
     localStorage.setItem('favs',JSON.stringify([]))
     } */
  }
}
