import { Location } from '@angular/common';
import { ItemService } from '../../../../../core/item.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-model3d',
  templateUrl: './model3d.component.html',
  styleUrls: ['./model3d.component.scss']
})
export class Model3dComponent implements OnInit {

  ar: boolean = false;
  vr: boolean = false;
  obj: boolean = false;
  obj2: boolean;
  productId;

  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private _location: Location,
    private service: ItemService
  )
  {
   
    this.loadData();
  }

  loadData() {
    this.productId = this.router.snapshot.paramMap.get('id');
    this.service.getproductitems(this.productId).subscribe(data => {
      let source: any = data;
      console.log(source)
      if(!source.data.data) this.obj2 = true;
      let a  = source.data.filter(res => res.type == "modl3DAr" ||  res.type == "modl3DVr");
       
      a.forEach(data => {

        if(data.type !== "modl3DAr"){
            this.obj2 = false;
        }else if(data.type !== "modl3DVr"){
          this.obj2 = false;
        }


        if (data.type === "modl3DAr") {
          this.ar = true;
          this.obj = true;
          
        }
        else if (data.type === "modl3DVr")
        {
          this.vr = true;
          this.obj = true;
          
        } 
        else
        {
          this.obj2 = false;
          console.log(this.obj,"← este objeto")
        }
      });
    });
  }

  ngOnInit() {
  }

}
