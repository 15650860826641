import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { Location } from '@angular/common';
import { CategoryService } from '../../core/category.service';
import { timeout } from 'rxjs/operators';
import { interval } from 'rxjs';
import {Router} from '@angular/router';


@Component({
  selector: 'app-clavos',
  templateUrl: './clavos.component.html',
  styleUrls: ['./clavos.component.scss']
})


export class ClavosComponent implements OnInit {
  @ViewChild('back', {static: false}) back : ElementRef;
  id;
  constructor(private service: CategoryService, private _location: Location, private _router : Router)
  {

  }

  ngOnInit() {
 
  }

  onNavigate(name){ 
    window.open( `https://frontapp.m3d-ar.com/resources/TORNILLOS/${name}.pdf`, "_blank"); 
  }

}
