import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { SubcategoryService } from 'src/app/core/subcategory.service';
import { Ruta } from 'src/app/config/routes.model';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  id;
  subcategories: any[];
  catId;
  obj: {};
  temp:{};
  img;
  imageList;
  constructor(
    private router: ActivatedRoute,
    private service: SubcategoryService,
    private route: Ruta
    )
  { 
    this.id = new Ruta()
      this.id={
        id: 2
    }
    this.imageList = ["clavo.png", "placa.png","Imagenes/Image10.png"]


    this.loadData();
  }

  ngOnInit() {

  }

  loadData() {
    this.catId = this.router.snapshot.paramMap.get('id');
    console.log(this.catId)
    this.service.getSubcategory2(this.catId).subscribe(data => {
      let source: any = data;
      this.obj = Object.keys(source.data).length === 0;
      if (this.obj !== 'undefined' && source.data.length > 0) {
        for (let ani of source.data) {
          if (ani.status === 1) {
            this.subcategories = source.data;
            this.obj = true;      
          } else {
            this.obj = false;
          }
        }
      } else {
      }
    });
  }
}
